var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadJob !== undefined
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _vm.label
                      ? _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { attrs: { text: "", small: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  title: "Download reports",
                                  color: "primary",
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-file-excel")]
                            ),
                            _vm._v(_vm._s(_vm.label) + " ")
                          ],
                          1
                        )
                      : _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              { attrs: { title: "Download reports" } },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v("mdi-file-excel")]
                        )
                  ]
                }
              }
            ],
            null,
            false,
            3018216857
          )
        },
        [
          _c("v-list", { staticClass: "text-center" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ],
                staticClass: "mx-2"
              },
              [
                _c("v-progress-circular", {
                  attrs: { size: "20", indeterminate: "", color: "primary" }
                }),
                _vm._v(" Download in progress... ")
              ],
              1
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading,
                    expression: "!isLoading"
                  }
                ]
              },
              _vm._l(_vm.reportsMenu(_vm.loadJob), function(menuItem, index) {
                return _c(
                  "v-list-item",
                  {
                    key: index,
                    attrs: { disabled: menuItem.disabled.value },
                    on: { click: menuItem.action }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", {
                          attrs: { color: menuItem.iconColor },
                          domProps: { textContent: _vm._s(menuItem.icon) }
                        })
                      ],
                      1
                    ),
                    _c("v-list-item-content", [_vm._v(_vm._s(menuItem.title))])
                  ],
                  1
                )
              }),
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }