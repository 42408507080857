
































import Vue from 'vue';
import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { useAuth, AssertionContext, userCanViewInternalReports } from '@/module/auth';
import {
  useLoadJobApi,
  LoadJobModel,
  isNewJobAndWizardIsNotComplete,
  LoadJobStateName,
  LoadJobType
} from '@/module/api/load-job';
import { useLoadJobValidationApi } from '@/module/api/load-job/validation';

const { assertionContext } = useAuth();

const {
  downloadManifestDetailReport,
  downloadJobDetailReport,
  downloadGeneratedPasswordReport,
  isLoading
} = useLoadJobApi();

const { downloadLoadJobValidationReport } = useLoadJobValidationApi();

const reportsMenu = (loadJob: LoadJobModel) => {
  const menu = [
    {
      title: 'Job Report',
      action: () => downloadJobDetailReport(loadJob.uuid || ''),
      icon: 'mdi-file-excel',
      iconColor: 'primary',
      description: 'Shows every action executed in a job and its result.',
      show: loadJob.uuid && true,
      disabled: loadJob.state !== LoadJobStateName.DONE || isLoading
    },
    {
      title: 'Manifest Report',
      action: () => downloadManifestDetailReport(loadJob.uuid || ''),
      icon: 'mdi-file-excel',
      iconColor: 'blue',
      description: 'Shows every operation that will be / has been run for this job.',
      show: loadJob.uuid && true,
      disabled: isNewJobAndWizardIsNotComplete(loadJob) || isLoading
    },
    {
      title: 'Validation Report',
      action: () => downloadLoadJobValidationReport(loadJob.uuid || ''),
      icon: 'mdi-file-excel',
      iconColor: 'success',
      description:
        'Shows validation details, and whether anything needs / needed to be fixed for the job to run.',
      show: loadJob.uuid && true,
      disabled: isNewJobAndWizardIsNotComplete(loadJob) || isLoading
    },
    {
      title: 'Agent Passwords File',
      action: () => {
        if (userCanViewInternalReports(assertionContext.value.roles)) {
          downloadGeneratedPasswordReport(loadJob.uuid || '');
        }
      },
      icon: 'mdi-lock',
      iconColor: 'primary',
      description:
        'Allows you to download usernames / passwords for new users that were generated via a job.',
      show: userCanViewInternalReports(assertionContext.value.roles) && loadJob.uuid != undefined,
      disabled:
        !userCanViewInternalReports(assertionContext.value.roles) ||
        loadJob.state !== LoadJobStateName.DONE ||
        loadJob.type !== LoadJobType.IMPORT ||
        isLoading
    }
  ];
  return menu.filter((v) => v.show);
};

export default defineComponent({
  name: 'LoadJobReportsButton',
  components: {},
  props: ['loadJob', 'label'],
  setup() {
    return {
      reportsMenu,
      isLoading
    };
  }
});
